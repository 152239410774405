// https://segment.com/docs/spec/
import packageJson from '../../package.json';

export const identify = (userId, traits) =>
  window && window.analytics.identify(userId, { ...traits, app_version: packageJson.version });

export const page = (title, properties) =>
  window && window.analytics.page(title, { ...properties, app_version: packageJson.version });

export const track = (event, properties) =>
  window && window.analytics.track(event, { ...properties, app_version: packageJson.version });

export const PRODUCT_SAME_DAY = 'Quiqdash Same Day';
export const FULFILLMENT = 'Quiqdash fulfillment';

export const TRACKING_CATEGORIES = {
  navigation: 'Navigation',
  support: 'Support',
  onboarding: 'Onboarding',
  liveOrders: 'Live Orders',
  liveOrder: 'Live Order',
  newOrderModal: 'New Order Modal',
  newOrder: 'New Order',
  orderConfirmationModal: 'Order Confirmation Modal',
  orderDetails: 'Order Details',
  history: 'History',
  historyDetails: 'History Details',
  addressBook: 'Address Book',
  account: 'account',
  zoneRestrictions: 'Zone Restrictions',
  ordersView: 'Orders View',
  singleShipment: 'Single Shipment',
  bulkUpload: 'Bulk Upload',
  configuration: 'Configuration',
  signup: 'Signup',
  login: 'Login',
  scheduledOrders: 'Scheduled Orders',
  scheduledOrderDetails: 'Scheduled Order Details',
  editParcel: 'Edit Parcel',
  editPayment: 'Edit Payment',
  invoices: 'Invoices',
  cod: 'COD',
  pickupAddresses: 'Pickup Addresses',
  company: 'Company Info',
  cutOff: 'Cut Off Time',
  app: 'App',
  trackOrder: 'Tracking order',
  downloadStock: 'Downloading stock',
  downloadOrders: 'Downloading orders',
  rates: 'Rates',
  integrations: 'Integrations',
  stripe: 'Stripe',
  paymentMethods: 'Payment methods',
  bankDetails: 'Bank Details',
  terms: 'Terms',
};

// All you need to know about TRACKING
// https://coda.io/d/Tracking-Plan_dtFayJzhpYT/Best-Practice_sucqY#_lutES
// @ts-check
/** @type (event: { [string]: string | number | undefined }): void */
export const trackEvent = ({
  action, // Action Value: Direct value of the event we are tracking, eg: for a toggle its true or false, for an input field its the text value, etc.. (Can be seen as the regular Value, but with generic type)
  category, // Category: events can be categorized by context, ex: Zone Restrictions, Live Jobs, Orders’ Listing, ..
  product, // Product: The app sending the event, QD, OMS, Dispatch etc..
  user, // User: is the user ID
  label, // Label: This property can serve  a direct value for the action we are tracking like the toggle value, clicked string, etc..
  value, // Value: This only accepts a numeric value
  location, // Location: The path under which the event has been thrown
  reason, // Reason: Reason for cancellation, etc..
  state, // State: Can be applied to order or app state
  form, // Form: Form values stringified (can be filtered on in custom reports)
  timeSpent, // Time Spent: Evaluate loading times in milliseconds
  count, // Count: Can be used to evaluate any quantitative value like result count
  kind, // Kind: Used to track orders’ kinds
  type, // Type: Can be used as any type tracked eg: waypoint type, payment type
  actionValue,
  query, // Query: Search queries on contacts or addresses or anything that requires a user input to fetch records
  errors, // Errors: Error as text or stringified json
}) => {
  track(action, {
    category,
    product,
    user,
    label,
    value,
    location,
    reason,
    state,
    form,
    time_spent: timeSpent,
    count,
    kind,
    type,
    action_value: actionValue,
    query,
    errors,
  });
};
