import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { Auth } from '@quiqupltd/quiqupjs';
import App from '../../containers/app/app';

const PUBLIC_ROUTES = ['/login', '/password-recovery', '/reset-password', '/create-account'];

const PrivateRoute = ({ component: Component, requiresLoggedOut, ...rest }) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!Auth.hasToken() && !PUBLIC_ROUTES.includes(location.pathname)) {
      history.push(`/login?returnUrl=${encodeURIComponent(`${location.pathname}${location.search}`)}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Routes that require you to be logged out: /signup, /login. In this case users should be redirected to /live
  if (requiresLoggedOut) {
    return (
      <Route
        {...rest}
        render={(props) =>
          Auth.hasToken() ? (
            <Redirect to={{ pathname: '/live', state: { nextPathname: rest.location.pathname } }} />
          ) : (
            <Component {...props} />
          )
        }
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        Auth.hasToken() ? (
          <App {...props}>
            <Component {...props} />
          </App>
        ) : (
          <Redirect to={{ pathname: '/login', state: { nextPathname: rest.location.pathname } }} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  requiresLoggedOut: PropTypes.bool,
  component: PropTypes.func.isRequired,
};

PrivateRoute.defaultProps = {
  requiresLoggedOut: false,
};

export default PrivateRoute;
